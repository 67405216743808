@use '../../../assets/colors';

.floating-action-button {
  display: flex;
  min-width: 46px;
  height: 46px;
  box-sizing: border-box;
  padding: 5px;
  margin: 3px 3px 10px 3px;
  border-radius: 100%;
  border: none;
  outline: none;
  background: colors.$white;
  box-shadow: 0 2px 4px colors.$shadow-light;
  justify-content: center;
  align-items: center;
}
