@use '../../../scss/variable/color';
@use '../../scss/settings/spacing';

.NotificationBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: var(--theme-border-radius-sm);

  &_info {
    background-color: var(--theme-color-info-light);
    --NotificationBox-icon-color: var(--theme-color-info);
  }

  &_warning {
    background-color: var(--theme-color-warning-light);
    --NotificationBox-icon-color: var(--theme-color-warning);
  }

  &_success {
    background-color: var(--theme-color-success-light);
    --NotificationBox-icon-color: var(--theme-color-success);
  }

  &_error {
    background-color: var(--theme-color-error-light);
    --NotificationBox-icon-color: var(--theme-color-error);
  }

  &__content {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: spacing.$s4;

      i {
        color: var(--NotificationBox-icon-color);
      }
    }

    &__message {
      display: flex;
      flex-direction: column;
    }
  }
}
