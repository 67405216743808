@use '../../../../../assets/colors';

.notification-button {
  background: var(--theme-color-warning);

  &--slide-in {
    animation-name: slide-in-notification-button;
    animation-duration: 0.35s;
    animation-fill-mode: forwards;

    @keyframes slide-in-notification-button {
      from {
        transform: translateX(100px);
      }
      to {
        transform: translateX(0);
      }
    }
  }

  &--slide-out {
    animation-name: slide-out-notification-button;
    animation-duration: 0.35s;
    animation-fill-mode: forwards;

    @keyframes slide-out-notification-button {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(100px);
      }
    }
  }
}
