.he-cursor-pointer {
  cursor: pointer;
}

.he-no-pointer-events {
  pointer-events: none;
}

.he-pointer-events-auto {
  pointer-events: auto;
}

.he-hidden {
  display: none;
}

.he-edgeindicator {
  fill: none;
  stroke: #E2A693;
  pointer-events: none;
}

.he-no-pointer-events-on-parent {
  pointer-events: none;

  * {
    pointer-events: auto;
  }
}
