@use '../../../../assets/colors';
@use '../../../../assets/spacing';
@use '../../../../assets/mixins';

.text-font-tiles {
  margin-bottom: spacing.$editable-margin-bottom;

  &__tile {
    @include mixins.text-editable-tile;
    background-color: colors.$white;
    border: 1px solid colors.$border-dark;

    &--active {
      box-shadow: 2px 2px 4px colors.$shadow-medium-dark;
    }

    > img {
      width: 100%;
    }
  }
}
