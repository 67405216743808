@use '../../../../assets/colors';
@use '../../../../assets/font-sizes';

.photo-upload {
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid colors.$border-light;
    background-color: colors.$white;

    &__icon {
      height: 35px;
      width: 35px;
      pointer-events: none;
    }
  }

  &__input {
    display: none;
  }
}
