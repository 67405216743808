@use '../../../scss/variable/breakpoint';
@use '../../../stories/scss/settings/spacing';
@use '../../../scss/variable/z-index';

.BadgeGroup {
  display: flex;
  flex-direction: column;
  z-index: z-index.$low-2;
  gap: spacing.$s1;

  &_offset_sm {
    --BadgeGroup-offset: #{spacing.$s1};

    @media screen and (min-width: breakpoint.$md) {
      --BadgeGroup-offset: #{spacing.$s2};
    }
  }

  &_offset_md {
    gap: spacing.$s2;
    --BadgeGroup-offset: #{spacing.$s2};

    @media screen and (min-width: breakpoint.$md) {
      --BadgeGroup-offset: #{spacing.$s4};
    }
  }

  &_offset_lg {
    gap: spacing.$s2;
    --BadgeGroup-offset: #{spacing.$s4};

    @media screen and (min-width: breakpoint.$md) {
      --BadgeGroup-offset: #{spacing.$s6};
    }
  }

  &_absolute {
    &_top {
      position: absolute;
      inset: var(--BadgeGroup-offset, 0) var(--BadgeGroup-offset, 0) auto var(--BadgeGroup-offset);
    }

    &_bottom {
      position: absolute;
      inset: auto var(--BadgeGroup-offset, 0) var(--BadgeGroup-offset, 0) auto;
    }
  }
}
