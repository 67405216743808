@use '../../../../assets/colors';
@use '../../../../assets/spacing';

.text-editable-group {
  display: flex;
  border: 1px solid colors.$border-dark;
  margin-bottom: spacing.$editable-margin-bottom;
  border-radius: 3px;
  margin-right: auto;

  &__item {
    display: inline-flex;
    width: 55px;
    height: 41px;
    border-right: 1px solid colors.$border-dark;
    justify-content: center;
    align-items: center;
    background: colors.$white;

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
      border-right: 0;
    }

    &:only-child {
      border-radius: 2px;
    }

    &--active {
      background-color: colors.$secondary;
      color: colors.$secondary--contrast;
      text-shadow: 1px 1px 2px colors.$shadow-dark;
    }

    &--disabled {
      background-color: colors.$light;
    }

    > .icon {
      max-width: 24px;
      max-height: 24px;
    }

    &--small-icons > .icon {
      max-height: 18px;
    }
  }
}
