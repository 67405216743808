@use '../../../assets/colors';
@use '../../../assets/font-sizes';

.app {
  &__composer-container {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
  }
}
