@use '../../../assets/colors';

.failed-photo {
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  &__notice {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1 auto;
    margin-left: 10px;
    word-break: break-word;

    &__message, &__filename {
      color: colors.$lighter-text;
    }

    &__message {
      font-size: 14px;
    }

    &__filename {
      font-size: 12px;
    }
  }

  &__image {
    border: 1px solid colors.$border-light;
    width: 75px;
    height: 75px;
  }
}
