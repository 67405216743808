@use '../../../../assets/colors';

.failed-photo-controls {

  &__actions {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__retry, &__continue {
      display: flex;
      flex: 1 1 auto;
      padding: 10px;
      border-radius: 5px;
      justify-content: center;
    }

    &__retry {
      background-color: colors.$ternary;
      color: colors.$ternary--contrast;
      margin-right: 5px;
    }

    &__continue {
      margin-left: 5px;
      background-color: colors.$medium-lighter;

      &--no-retry {
        background-color: colors.$ternary;
        color: colors.$ternary--contrast;
        margin-left: 0;
      }
    }
  }
}
