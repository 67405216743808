@use '../../../../scss/variable/font';
@use '../../../../stories/scss/settings/spacing';

.InformationBlock {
  &_titles {
    &__subtitle {
      margin-top: spacing.$s2;
      margin-bottom: spacing.$s2;
      font-family: font.$primary;
      font-size: font.$size-base;
      color: var(--theme-font-color-light);
    }
  }
}
