@use '../../../../../../stories/scss/settings/spacing';

.List {
  border: 1px solid var(--theme-color-base);
  border-radius: var(--theme-border-radius-sm);
  width: 100%;

  &__Item {
    border-bottom: 1px solid var(--theme-color-base);
    cursor: pointer;
    display: block;

    &:last-child {
      border-bottom: none;
    }

    &__Name, &__Price {
      padding-top: 2px;
      line-height: 1;
    }
  }
}
