@use '../../../../assets/colors';

.text-content {

  &__textbox {
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &__input {
      width: 100%;
      height: 43px;
      padding: 8px 35px 8px 14px;
      color: colors.$black;
      background: colors.$white;
      border: 1px solid colors.$border-dark;
      border-radius: 5px;

      &[readonly] {
        background: colors.$white;
      }
    }
  }
}
