@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/font';

.Specifications {
  h2 {
    font-size: font.$size-xs;

    @media screen and (min-width: breakpoint.$md) {
      font-size: font.$size-sm;
    }
  }

  h3 {
    font-size: font.$size-xs - 2;

    @media screen and (min-width: breakpoint.$md) {
      font-size: font.$size-xs;
    }
  }

  h4 {
    font-size: font.$size-xs - 4;
  }

  h5 {
    font-size: font.$size-xs - 6;
  }
}
