@use '../../../scss/variable/breakpoint';
@use '../../scss/settings/spacing';

.List {
  display: grid;
  row-gap: spacing.$s2;

  &_gap {
    &_base {
      row-gap: spacing.$s2;
    }
    &_default {
      row-gap: spacing.$s4;
    }
  }

  &_horizontal {
    column-gap: spacing.$s4;

    &_mobile {
      @media screen and (max-width: breakpoint.$lg) {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      }
    }
    &_desktop {
      @media screen and (min-width: breakpoint.$lg) {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;

    i {
      margin-right: spacing.$s4;
    }
  }
}
