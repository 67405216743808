@use '../../../assets/colors';
@use '../../../assets/animations';

.design-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  flex: 0 0 auto;
  margin: 0 5px 5px 5px;
  border: 1px solid colors.$medium-light;
  transition: box-shadow 0.1s;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &--active {
    border: 1px solid colors.$border-dark;
    box-shadow: 2px 2px 3px colors.$shadow-medium-dark;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    margin: auto;
    vertical-align: middle;
  }

  > .icon {
    animation: fade-in 0.1s ease-in;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 22px;
    height: 22px;
    background-color: colors.$white;
    border-radius: 50%;
  }
}
