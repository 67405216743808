@use '../../../../assets/colors';

.failed-photo-collection {
  &__caption {
    padding: 5px 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    &--warning {
      background-color: colors.$warning-faded;
    }

    &--fatal {
      background-color: colors.$danger-faded;
    }

    .icon {
      width: 24px;
      margin-right: 10px;
    }
  }
}
