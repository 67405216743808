@use 'sass:color' as SassColor;

// stylelint-disable color-no-hex
// Grayscale
$white: #FFFFFF;
$light: #F4F3F2;
$light-transparent: #F4F3F2D4;
$medium-lighter: #E1E1E1;
$medium-light: #CCCCCC;
$medium: #BBBBBB;
$medium-dark: #9E9E9E;
$black: #000000;

// Alerts
$warning: #FBCA6D;
$danger: #DE2E2E;

$warning-faded: SassColor.adjust($warning, $lightness: 13%);
$danger-faded:  SassColor.adjust($danger, $lightness: 35%);

// Color palette
$primary: var(--theme-color-secondary);
$primary--contrast: var(--theme-color-white);
$secondary: #B2D0CB;
$secondary--contrast: $white;
$ternary: #85A09B;
$ternary--contrast: $white;

// Borders
$border-light: #D6D6D6;
$border-dark: #6F6F6F;

// Shadows
$shadow-dark: #666666;
$shadow-medium-dark: #9E9E9E;
$shadow-light: #BBBBBB;
$shadow-light-transparent: #BBBBBB80;

$lighter-text: #656565;
