div.slider-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  > div {
    &:focus {
      outline: none;
    }
  }
}
