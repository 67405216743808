@use '../../../assets/spacing';
@use '../../../assets/colors';

.action-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: spacing.$action-bar-height;
  background: colors.$white;
  border-bottom: 1px solid colors.$border-light;

  &--slide-in {
    $from: spacing.$action-bar-height-outer;
    animation-name: slide-in-action-bar;
    animation-duration: 0.35s;
    animation-fill-mode: forwards;

    @keyframes slide-in-action-bar {
      from {
        transform: translateY(-$from);
      }
      to {
        transform: translateY(0);
      }
    }
  }

  > button {
    width: 100%;
    height: 100%;
    padding: 0 20px 0 0;
    background: inherit;
    font-size: inherit;
    border: none;
    text-align: right;

    > .icon {
      width: 28px;
    }
  }
}
