$mobile-min-size: 320px !default;
$mobile-max-size: 750px !default;
$tablet-max-size: 970px !default;
$desktop-large-min-size: 1170px !default;
$desktop-extra-large-min-size: 1920px !default;

@mixin breakpoint($media) {
    @if $media == mobile {
        @media all and (max-width: $mobile-max-size) {
            @content;
        }
    } @else if $media == mobile-tablet {
        @media all and (max-width: $tablet-max-size) {
            @content;
        }
    } @else if $media == tablet {
        @media all and (min-width: $mobile-max-size) and (max-width: $tablet-max-size) {
            @content;
        }
    } @else if $media == tablet-landscape {
        @media all and (min-width: $mobile-max-size) and (max-width: $tablet-max-size) and (min-aspect-ratio: 13/9) {
            @content;
        }
    } @else if $media == tablet-portrait {
        @media all and (min-width: $mobile-max-size) and (max-width: $tablet-max-size) and (max-aspect-ratio: 13/9) {
            @content;
        }
    } @else if $media == tablet-desktop {
        @media all and (min-width: $mobile-max-size) {
            @content;
        }
    } @else if $media == desktop {
        @media all and (min-width: $tablet-max-size) {
            @content;
        }
    } @else if $media == desktop-large {
        @media all and (min-width: $desktop-large-min-size) {
            @content;
        }
    } @else if $media == desktop-extra-large {
        @media all and (min-width: $desktop-extra-large-min-size) {
            @content;
        }
    } @else {
        @content;
    }
}
