@use '../../../../assets/colors';
@use '../../../../assets/spacing';
@use '../../../../assets/animations';

.photo-controls {
  $max-height: 30%;
  $animation-duration: 0.35s;
  $animation-name: 'photo-controls-slide-in';
  background: colors.$light;
  width: 100%;
  max-height: 50%;
  overflow: scroll;

  @include animations.slide-in-partially($animation-name, $max-height);

  &__wrapper {
    padding: 10px;
    width: 100%;
    background: colors.$light;
    border-top: 1px solid colors.$border-light;
    overflow-y: hidden;
  }

  &--from-hidden, &--from-overlay {
    animation-fill-mode: forwards;
    animation: $animation-name $animation-duration linear;
  }

  &--from-fullscreen {
    animation-name: fullscreen-to-overlay;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;

    @keyframes fullscreen-to-overlay {
      from {
        min-height: calc(100% - #{spacing.$action-bar-height});
      }
      to {
        min-height: 0;
      }
    }
  }
}
