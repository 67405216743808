@use '../../../assets/colors';

.design-filter {
  position: relative;
  margin-top: 10px;

  &__select {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 8px 8px;

    // Native styling overwrites
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 1px solid colors.$medium-light;
    background-color: colors.$white;
    border-radius: 5px;
  }

  &__icon {
    pointer-events: none;
    position: absolute;
    right: 20px;
    bottom: 3px;
    width: 20px;
  }
}
