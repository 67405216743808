.floating-action-bar {
  position: absolute;
  top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 2px;
  overflow: hidden;

  &--left {
    left: 16px;
  }

  &--right {
    right: 16px;
  }
}
