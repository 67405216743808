$colorDefault: #B4769F;
$colorDefaultDark: darken($colorDefault, 10%);
$colorDefaultLight: lighten($colorDefault, 10%);
$colorDefaultUltraLight: lighten($colorDefault, 20%);
$colorGrey: #6B6B6B;
$colorMidLightGrey: #909090;
$colorGreyHover: lighten($colorGrey, 10%);
$colorMidLighterGrey: #8D8D8D;
$colorLightGrey: lighten($colorGrey, 40%);
$colorGreyAlmostWhite: #F2F2F2;
$colorUltraGreyAlmostWhite: #F4F3F2;
$colorUltraLightGrey: lighten($colorGrey, 52%);
$colorUltraLightGreyHover: lighten($colorGrey, 53%);
$colorUltraLightGreyFocus: lighten($colorGrey, 48%);
$colorLightDarkGrey: #DDDDDD;
$colorMidGrey: #C5C3C4;
$colorDarkGrey: #4A4A4A;
$colorSmoke: #737373;
$colorMidDarkGrey: #7C8D95;
$colorBlack: #000000;
$colorWhite: #FFFFFF;
$colorBlue: #A2B5D3;
$colorBlueHover: lighten($colorBlue, 10%);
$colorLightBlue: lighten($colorBlue, 20%);
$colorDarkBlue: darken($colorBlue, 10%);
$colorGreen: #81AF28;
$colorSoftGreen: #A9BA59;
$colorLightGreen: lighten($colorGreen, 10%);
$colorUltraLightGreen: lighten($colorGreen, 48%);
$colorDarkGreen: darken($colorGreen, 10%);
$colorOrange: #F5A623;
$colorLightOrange: lighten($colorOrange, 10%);
$colorDarkOrange: darken($colorOrange, 10%);
$colorWarning: $colorOrange;
$colorWarningLighter: lighten($colorWarning, 40%);
$colorGentleWarning: $colorOrange;
$colorWarningLight: lighten($colorWarning, 50%);
$colorRedLight: #E36A6A;
$colorRed: #D97C78;
$colorError: $colorRed;
$colorErrorLighter: lighten($colorError, 15%);
$colorErrorUltraLight: lighten($colorError, 25%);
