@use '../../../assets/font-sizes';

.notification-overlay-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.notification-overlay {
  position: absolute;
  top: -340px;
  left: 8px;
  right: 8px;
  min-height: 80px;
  max-height: 60%;
  padding: 35px 20px 0;
  background: var(--theme-color-warning-light);
  box-sizing: border-box;
  font-size: font-sizes.$large;
  overflow-y: auto;

  animation-name: slide-in-overlay;
  animation-duration: 0.35s;
  animation-fill-mode: forwards;

  @keyframes slide-in-overlay {
    to {
      transform: translateY(420px);
    }
  }

  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    padding: 0;
    background: none;
    border: none;
    outline: none;

    > .icon {
      width: 20px;
      height: 20px;
    }
  }

  &__notification {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    > .icon {
      align-self: flex-start;
      width: 34px;
      height: 34px;
    }

    &__message {
      margin-left: 16px;
    }
  }
}
