@use '../../../assets/colors';

.user-photo {
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 1px solid colors.$border-light;
  }

  &__progress {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 29px;
    height: 29px;
  }

  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    background-color: colors.$white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    top: 5px;
    right: 5px;
    border: 1px solid colors.$border-light;

    svg {
      margin-left: 1px;
      width: 16px;
      height: 16px;
    }
  }
}
