@use './spacing';

@mixin text-editable-tile {
  width: 44px;
  min-width: 44px;
  height: 44px;
  padding: 5px;
  margin: 0 spacing.$app--small 4px 0;
  border-radius: 3px;
  transition: box-shadow, background-color 100ms;
}
