@use '../../scss/settings/spacing';

.Flex {
  display: flex;

  &_wrap {
    flex-wrap: wrap;
  }

  &_columnGap {
    @each $name, $value in spacing.$spacingsArray {
      &_#{$name} {
        column-gap: $value;
      }
    }
  }

  &_rowGap {
    @each $name, $value in spacing.$spacingsArray {
      &_#{$name} {
        row-gap: $value;
      }
    }
  }

  &_alignContent {
    &_start {
      align-content: start;
    }
    &_center {
      align-content: center;
    }
    &_spaceBetween {
      align-content: space-between;
    }
    &_spaceAround {
      align-content: space-around;
    }
    &_end {
      align-content: end;
    }
  }

  &_alignItems {
    &_start {
      align-items: start;
    }
    &_end {
      align-items: end;
    }
    &_center {
      align-items: center;
    }
    &_stretch {
      align-items: stretch;
    }
    &_baseline {
      align-items: baseline;
    }
  }

  &_direction {
    &_row {
      flex-direction: row;
    }
    &_column {
      flex-direction: column;
    }
    &_rowReverse {
      flex-direction: row-reverse;
    }
    &_columnReverse {
      flex-direction: column-reverse;
    }
  }

  &_justify{
    &_start {
      justify-content: start;
    }
    &_end {
      justify-content: end;
    }
    &_center {
      justify-content: center;
    }
    &_spaceBetween {
      justify-content: space-between;
    }
    &_spaceAround {
      justify-content: space-around;
    }
    &_spaceEvenly {
      justify-content: space-evenly;
    }
  }
}
