@use '../../assets/colors';
@use '../../assets/font-sizes';

.mobile-quick-editor {
  overflow: hidden;
  height: 100%;
  max-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: colors.$white;
  border-radius: 5px;
  border: 1px solid rgb(214, 214, 214);
  box-shadow: 1px 1px 10px rgb(214, 214, 214);

  * {
    box-sizing: border-box;
  }

  input {
    font-size: font-sizes.$minimum_font_size_to_prevent_safari_auto_zoom + 2px;
  }

  button {
    border: none;
    outline: none;
    line-height: 100%;
  }

  select {
    font-size: font-sizes.$minimum_font_size_to_prevent_safari_auto_zoom;
  }

  .icon {
    flex-shrink: 0;
  }

  .he-preview-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .he-layout-preview .he-preview-loader {
    $giftEditorWidgetLoaderOffset: #{15px + 1px};

    position: fixed;
    top: $giftEditorWidgetLoaderOffset;
    left: $giftEditorWidgetLoaderOffset;
    right: $giftEditorWidgetLoaderOffset;
    bottom: $giftEditorWidgetLoaderOffset;
    width: auto;
    height: auto;
    border-radius: 5px;
  }
}

#mobile-quick-editor-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  * {
    pointer-events: none;
  }

  .he-layout-preview {
    width: inherit;
    height: inherit;
    margin: 0;
  }
}
