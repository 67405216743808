@import '../../style/base/color.scss';
@import '../../style/main/breakpoints.scss';

.he-layout-preview, .he-suuz-layout-preview {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 98%;
  width: 98%;
  margin: 1%;
  overflow: hidden;

  .he-layout-title {
    align-self: center;
    padding: 0 5px 4px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.he-preview-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $colorWhite;
  width: 100%;
  height: 100%;

  &--hidden {
    display: none;
  }
}

.he-spread-container, .he-personalize-container, .he-composer-svg-preview, .he-order-container {
  position: absolute;
}

.he-preview-container, .he-personalize-composer, .he-spread-composer  {
  position: relative;
}

.he-composer-zoom-container,
.he-composer-background,
.he-personalize-container,
.he-spread-container,
.he-preview-container,
.he-zoom-container,
.he-preview-background {
  display: flex;
  flex: 1;
  flex-direction: column;

  height: 100%;
  width: 100%;

  @include breakpoint(desktop) {
    flex-direction: row;
  }
}

.he-personalize-composer, .he-spread-composer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  overflow: hidden;
  background-color: $colorWhite;
}

.he-preview-svg {
  position: relative;
}

.he-zoom-container {
  display: flex;
  overflow: hidden;

  .he-preview-background {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    overflow: hidden;
  }

  .he-preview-div {
    overflow: hidden;
    position: absolute;
  }
}

.he-3d-preview-wrapper {
  flex: 1 0 auto;
  display: flex;
  width: 100%;
  height: 100%;
}

.he-3d-previews {
  align-items: center;
  justify-content: center;
	aspect-ratio: 1/1;
  margin: auto;

  &.portrait {
    width: 100%;
    height: auto;
  }

  &.landscape {
    width: auto;
    height: 100%;
  }
}

.he-composer-zoom-container {
  position: relative;
  overflow: hidden;

  .he-composer-background {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
  }

  .he-composer-div {
    border-style: solid;
    border-width: 1px;
    position: absolute;
    border-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAANElEQVQYV72QMQoAIBDDmv8/OqI4nOCN2qmFDKEkMSV6zABBldn6+BFaOlu8dXoA3b6pjwwk7S31cHdi3wAAAABJRU5ErkJggg==') 1 round;
  }
}

.center-pre-prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.center-pre-prompt.hide {
  display: none;
}

@keyframes wiggle {
  10%, 12% { transform: translateX(-25px); }
  30%, 32% { transform: translateX(25px); }
  0%, 45%, 100% { transform: translateX(0%); }
}

@keyframes fade {
  5%, 40% {	opacity: 1; }
  0%, 45%, 100% {	opacity: 0; }
}

.pre-prompt {
  pointer-events: none;
  animation-name: wiggle, fade;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.progress-bar {
  display: block;
  width: 33%;
  height: 10%;
  max-height: 2%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 25px;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 1px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
}

.progress-bar.hide {
  visibility: hidden;
  transition: visibility 0.3s;
}

.update-bar {
  background-color: rgba(255, 255, 255, 0.9);
  width: 0%;
  height: 100%;
  border-radius: 25px;
  float: left;
  transition: width 0.3s;
}
