.photo-tile {
  position: relative;
  width: 80px;
  min-width: 80px;
  height: 80px;
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }
}
