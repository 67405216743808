@use '../../scss/settings/spacing';
@use '../../../scss/variable/transition';
@use '../../../scss/mixin/flexbox';
@use '../../../scss/mixin/container';

.Button {
  $root: &;

  @include flexbox.flex-align-justify($_flex__justify-content: center);
  font-family: var(--theme-font-family-primary);
  font-weight: var(--theme-button-font-weight-default);
  font-size: var(--theme-font-size-base);
  transition: transition.$default background-color;
  border-radius: var(--theme-border-radius-sm);
  border: var(--theme-button-border);
  color: var(--theme-font-color);
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  padding: 0 spacing.$s6;
  width: fit-content;
  white-space: nowrap;

  > span {
    @include flexbox.flex-align-justify($_flex__justify-content: center);
  }

  &__hidden {
    visibility: hidden;
  }

  &__icon {
    width: spacing.$s4;
    margin-right: spacing.$s3;
  }

  &_loading {
    position: relative;

    #{$root}__icon_centered {
      @include container.absolute-center-hv;
    }
  }

  &_primary {
    color: var(--theme-element-color-primary);
    background-color: var(--theme-color-primary);
    box-shadow: var(--theme-button-primay-box-shadow);
    font-weight: var(--theme-button-font-weight);
    border: 0;

    &:hover {
      background-color: var(--theme-color-primary-dark);
    }
  }

  &_secondary {
    color: var(--theme-element-color-secondary);
    background-color: var(--theme-color-secondary);
    font-weight: var(--theme-button-font-weight);
    border: 0;

    &:hover {
      background-color: var(--theme-color-secondary-dark);
    }
  }

  &_white {
    background-color: var(--theme-color-white);
  }

  &_small {
    --inline-button-border-radius: var(--theme-element-size-sm);
    font-size: var(--theme-font-size-xxs);
    height: var(--theme-element-size-sm);
    padding: 0 12px;
  }
  
  &_editor {
    --inline-button-border-radius: var(--theme-element-size-md);
    font-weight: normal;
    height: 40px;
    padding: 0 14px;
  }

  &_medium {
    --inline-button-border-radius: var(--theme-element-size-md);
    height: var(--theme-element-size-md);
    min-width: 105px;
  }

  &_large {
    --inline-button-border-radius: var(--theme-element-size-lg);
    height: var(--theme-element-size-lg);
    width: 100%;
    min-width: 170px;
  }

  &_fluid {
    width: 100%;
  }

  &_rounded {
    border-radius: var(--inline-button-border-radius);
  }

  &_square {
    border: solid 1px var(--theme-color-base);
    min-width: unset;
    min-height: unset;
    padding: 0;
    aspect-ratio: 1/1;
    transition: 0.2s ease-in border-color;

    &:hover {
      border-color: var(--theme-color-base-dark);
    }
  }

  &_clean {
    border: 0;
    background-color: transparent;
    padding: 0;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
