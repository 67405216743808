@use '../../../../assets/colors';
@use '../../../../assets/spacing';
@use '../../../../assets/animations';

.text-controls {

  $animation-duration: 0.35s;
  background: colors.$light;
  width: 100%;

  &__overlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background: colors.$light;
    padding: spacing.$app;
    border-top: 1px solid colors.$border-light;

    > div {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__fullscreen {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: spacing.$app;
    height: calc(100% - #{spacing.$action-bar-height});
    background-color: colors.$light;

    > div {
      &:last-child {
        margin-top: 0;
      }
    }
  }

  &--from-hidden, &--from-overlay {
    animation: slide-in $animation-duration linear;

    .text-controls__fullscreen {
      animation: slide-in $animation-duration linear;
    }
  }

  &--from-fullscreen {
    animation-name: fullscreen-to-overlay;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;

    @keyframes fullscreen-to-overlay {
      from {
        min-height: calc(100% - #{spacing.$action-bar-height});
      }
      to {
        min-height: 0;
      }
    }
  }
}
