@use '../../../../../assets/colors';
@use '../../../../../assets/font-sizes';

.close-quick-editor-button {
  box-shadow: none;
  background: colors.$white;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  padding: 15px;

  > .icon {
    // stylelint-disable-next-line color-no-hex
    fill: #00000085;
  }
}
