@use '../../../../../assets/colors';
@use '../../../../../assets/font-sizes';

.add-to-cart-button {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  color: colors.$primary--contrast;
  font-size: font-sizes.$large;
  background: var(--theme-color-primary);
  margin-bottom: 20px;

  > .icon {
    fill: colors.$white;
    width: 39px;
    height: 42px;
  }

  &[disabled] {
    opacity: 0.7;
  }
}
