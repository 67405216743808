@use '../../../scss/variable/breakpoint';
@use '../../../scss/variable/color';
@use '../../../scss/variable/font';
@use '../../../stories/scss/settings/spacing';

.Badge {
  border-radius: var(--theme-element-size-sm);
  padding: 0 spacing.$s3;
  width: fit-content;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: font.$size-xxs;
  height: 25px;
  line-height: 26px;

  &_lowercase {
    text-transform: capitalize;
  }

  &_small {
    font-size: var(--theme-font-size-min);
    padding: 0 spacing.$s2;
  }

  &_color {
    &_red {
      background-color: var(--theme-color-error);
      color: var(--theme-color-white);
    }

    &_pink_lighter {
      background-color: var(--theme-color-secondary-light);
      color: var(--theme-color-error);
    }

    &_transparent {
      background-color: transparent;
      color: var(--theme-color-error);
    }

    &_honey {
      background-color: color.$honey;
      color: var(--theme-color-white);
    }

    &_blue {
      background-color: color.$blue;
      color: var(--theme-color-white);
    }
  }
}
