@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    max-height: 0;
  }
  to {
    max-height: 100%;
  }
}

@mixin slide-in-partially($name, $max-height) {
  @keyframes #{$name} {
    from {
      max-height: 0;
    }
    to {
      max-height: $max-height;
    }
  }
}

