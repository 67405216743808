@use '../../../../assets/colors';
@use '../../../../assets/spacing';
@use '../../../../assets/mixins';

.text-color-tiles {
  margin-bottom: spacing.$editable-margin-bottom;

  &__tile {
    @include mixins.text-editable-tile;
    border: 1px solid transparent;

    &--active {
      border: 1px solid colors.$border-dark;
      box-shadow: 2px 2px 4px colors.$shadow-medium-dark;
    }
  }
}
