@use '../../../assets/colors';

.design-picker {
  position: relative;
  background-color: colors.$light;
  border-top: 1px solid colors.$border-light;

  &__tiles {
    margin: 10px 10px 5px 10px;
  }
}
