@use '../../../assets/font-sizes';

.app__load-failed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 100%;
    max-height: 100%;
  }

  &__text {
    text-align: center;
    font-size: font-sizes.$large;
  }
}
