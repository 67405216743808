@use '../../../../assets/colors';

.text-content-count {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: colors.$medium-dark;
  border-radius: 0 5px 5px 0;

  &--max-reached {
    color: colors.$danger;
  }
}
