@use '../../../assets/colors';
@use '../../../assets/animations';

.instruction {
  display: flex;
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 10px;
  justify-content: center;
  pointer-events: none;
  animation: fade-in 0.3s linear;

  &__content {
    display: flex;
    background-color: colors.$light-transparent;
    box-shadow: 0 2px 4px colors.$shadow-light-transparent;
    border-radius: 30px;
    padding: 10px 20px;

    &__text {
      display: inline-flex;
      align-self: center;
    }

    > .icon {
      width: 36px;
      margin-right: 3px;
    }
  }
}
