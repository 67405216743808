@use '../../../../stories/scss/settings/spacing';
@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/z-index';

.IssuuPreview {
  &__button {
    position: absolute;
    top: spacing.$s6;
    left: spacing.$s6;
    z-index: z-index.$low-2;
  }
}
