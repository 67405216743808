.mobile-quick-editor-overlay {
  max-width: 100vw;
  max-height: 100vh;
  box-sizing: border-box;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.84);
}
